<header><h1>Validación de DN</h1></header>

<div class="container text-center mb-5">
  <div class="col-md-10 mx-auto mt-5">
    <div class="col-6 mx-auto">

        <p>¿ESTÁS SEGURO QUE QUIERES CANCELAR LA OPERACIÓN?</p>
        <div class="mb-3">
            <img class="me-3" src="assets/images/si-circle.png" alt="">
            <img src="assets/images/no-circle.png" alt="">
        </div>
        
        <div class="col-md-12 control">
            <input type="text" placeholder="Comentario" />
        </div>
    </div>
    <div class="col-md-6 mx-auto bg mt-5">
        <div class="title p-3">Contactanos</div>
        <div class="p-3">
    
            <h3><span>PUNTA ALTA, Buenos Aires</span></h3>
            <p>Cesar Treffinger</p>
            <p>Rivadavia 166, Punta Alta, Buenos Aires</p>
            <p>02932-426890</p>
            <p>Facebook</p>
        </div>
      </div>
    <div class="mt-5">
      <button class="transparent mt-4" routerLink="/">
        CANCELAR OPERACIÓN
        <div>comentario</div>
      </button>
    </div>
    <div>
      <div class="logo mt-3">
        <img src="assets/images/logo_grey.png" alt="" />
      </div>
    </div>
  </div>
</div>
