<header><h1>PAGO</h1></header>

<div class="container">
  <div class="row my-5">
    <div class="col-md-6 mx-auto bg">
        <div class="row">

            <div class="title p-3 text-center">Medio de pago</div>
        </div>
      <div class="p-3">
        <p>Verificar los datos del medio de pago de tu plan</p>
        <form class="mx-auto d-block" action="">
          <div class="row">
            <div class="col-12 control">
              <div>Convenio de pag</div>
              <input type="text" />
            </div>

            <div class="row">
              <div class="col-md-12 control">
                <div>° de tarjeta / N° cuenta bancaria</div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <div>Valida hasta</div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <div>
                  Nombre y apellido del titular de la tarjeta/cuenta bancaria
                </div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <div>DNI</div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <div>Correo</div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <div>Importe 1er débito</div>
                <input type="text" />
              </div>
              <div class="col-md-12 control">
                <input class="me-3" type="checkbox" style="width: initial" />
                <label for="">Ajusta Valor Nominal automático</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div></div>
    </div>

</div>
<div class="row">

    <div class="col-md-4 text-center mx-auto">
        <div class="row">
            <button class="basic mt-4" routerLink="/bases-y-condiciones">
                Siguiente
            </button>
            <button class="transparent mt-4" routerLink="/cancelar-operacion">
                CANCELAR OPERACIÓN
                <div>comentario</div>
            </button>
            
            <div class="logo mt-3">
                <img src="assets/images/logo_grey.png" alt="" />
            </div>
        </div>
      </div>
    </div>
</div>
