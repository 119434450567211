import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer, SafeHtml, Title} from '@angular/platform-browser'
import { ClientService } from 'src/app/services/user.service';
@Component({
  selector: 'app-attached-files',
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss']
})
export class AttachedFilesComponent implements OnInit {

  id : string | null = "";
  code : string | null  = "";
  marca : string  = "";
  colores : any = {
    C : "DC002E",
    P : "162133",
    F : "FF1430",
    J : "ffba00"
  }
  color = "162133";
  image = "assets/logos/peugeot.jpg"
  images : any = {
    "C" :"assets/logos/citroen.jpg",
    "P" : "assets/logos/peugeot.jpg",
    "F" : "assets/logos/fiat.png",
    "J" : "assets/logos/jeep.png"
  }
  names : any = {
    "C" :"Citroën",
    "P" : "Peugeot",
    "F" : "Fiat",
    "J" : "Jeep"
  }
  marcaname = "";

  titles : any = {
    "C" :"PSA",
    "P" : "PSA",
    "F" : "FCA",
    "J" : "FCA"
  }
  files :any = []
  constructor(private route: ActivatedRoute,private title: Title,private userService : ClientService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.id = params.get('strIdEnvio');
      this.code = params.get('strIdEnvioCod');
      this.marca = params.get('marca') || 'F';
      this.title.setTitle(this.titles[this.marca]);
      this.image = this.images[this.marca]
      this.color = this.colores[this.marca]
      this.marcaname = this.names[this.marca]
      this.loadFiles()
    }
  );

  }

  loadFiles(){
    this.userService.attachments({id: this.id,code: this.code}).subscribe(
      (result) => { 
        this.files = result.data

      }
    )
  }

}
