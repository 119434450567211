<header><h1>Autocreditos</h1></header>

<div class="container text-center mb-5">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <div class="avatar mx-auto my-3">
        <img src="assets/images/avatar.png" alt="" />
      </div>
      <h2><span>¡Bienvenida</span> Claudia!</h2>
      <h3 class="mb-4"><span>Podés suscribirte en etos simples pasos</span></h3>
      <div class="steps">
        <div class="row w-100">
          <div class="col-md-3">
            <div class="number mx-auto">1</div>
            <div class="image">
              <img class="w-100" src="assets/images/bucket-1.png" alt="" />
            </div>
            <div class="d-md-none mb-4"><img src="assets/images/ic-arrow-vertical.png" alt=""></div>
          </div>
          <div class="col-md-3">
            <div class="number mx-auto">2</div>
            <div class="image">
              <img class="w-100" src="assets/images/bucket-2.png" alt="" /> 
              <div class="d-md-none mb-4"><img src="assets/images/ic-arrow-vertical.png" alt=""></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="number mx-auto">3</div>
            <div class="image">
              <img class="w-100" src="assets/images/bucket-3.png" alt="" />
              <div class="d-md-none mb-4"><img src="assets/images/ic-arrow-vertical.png" alt=""></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="number mx-auto">4</div>
            <div class="image">
              <img class="w-100" src="assets/images/bucket-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 mx-auto">

        <h3>
          <span>Importante:</span> tenés que ser mayor de 18 años, la suscripción
          queda sujeta a verifiación
        </h3>
      </div>

      <button class="basic mt-4" routerLink="/validar/datos">Siguiente</button>
      <div class="logo mt-3"><img src="assets/images/logo.png" alt="" /></div>
    </div>
  </div>
</div>
