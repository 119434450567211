import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';


@Component({
  selector: 'app-validate-proof-life',
  templateUrl: './validate-proof-life.component.html',
  styleUrls: ['./validate-proof-life.component.scss']
})
export class ValidateProofLifeComponent implements OnInit, AfterViewInit {

  hints: String = "Ahora vamos a realizar la prueba de vida";
  seconds : String = "";
  showButton : Boolean = false;
  timeLeft: number = 4;
  interval : any;
  continueButton = false;
  step = 'laugh';
  images : any = {
    serious: null,
    laugh: null
  }

  constructor() { }

  ngOnInit(): void {
    this.width =window.innerWidth;
    console.log(this.width);
  }

  ngAfterViewInit() {
    if (this.container) {
      console.log('Width:' + this.width);
      this.width = this.container.nativeElement.offsetWidth;
    }else{
      this.width =window.innerWidth;
      console.log(this.width);
    }
   setTimeout(()=>{
    this.checkPermissions()
   }, 5000)
   

    
  } 

  @ViewChild('container')
  container: ElementRef | undefined;

  public webcamImage : any = null;

  width = 500
  public showWebcam = true;
  public multipleWebcamsAvailable = false;
  stream: any = null;
  status: any = null;
  trigger: Subject<void> = new Subject();
  previewImage: string = '';
  facingMode: string = 'user';  //Set front camera
  allowCameraSwitch = false;
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  btnLabel: string = '';

  get $trigger(): Observable<void> {
    return this.trigger.asObservable();
  }


  public handleImage(webcamImage: WebcamImage): void {
    this.status =  'Received webcam image';
   // this.previewImage = webcamImage.imageAsDataUrl;
    this.images[this.step] = webcamImage;
    console.log(this.images);
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.status = 'User denied camera access';
    }
  }
  public cameraWasSwitched(deviceId: string): void {
    this.status = 'Active device: ' + deviceId;
   
    this.readAvailableVideoInputs();
  }


  private readAvailableVideoInputs() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
  
  checkPermissions() {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: this.width ,
        height: 500
      }
    }).then((res) => {
      this.stream = res;
      this.status = 'My camera is accessing';
      this.showButton = true;
      this.btnLabel = "Capturar Frete"
    }).catch(err => {
      console.log(err);
      alert(err);
      if(err?.message === 'Permission denied') {
        this.status = 'Permission denied please try again by approving the access';
      } else {
        this.status = 'You may not having camera system, Please try again ...';
      }
    })
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }


  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    var result: MediaTrackConstraints = {
       width: {exact: this.width },
        height:{max:640,min:300}
    };
      result.facingMode =  { ideal: "user" }
    
    return result;
  }

  submit(){
    
  }

  startProcess(step : string){
    this.showButton = false;
    console.log(this.interval);
    console.log("starting process");
    this.step = step;
    this.timeLeft = 7
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        if (this.timeLeft > 4 ){
          
          switch(this.step){
            case 'laugh' : 
            this.hints = "Sonrie para la primer foto";
            break;
            case 'serious':
            this.hints = "Ahora necesitamos una foto serio";
          }
        }else{
          this.seconds = String(this.timeLeft)
        }
        this.timeLeft--;
      } else {
        this.captureImage();
        this.hints = "Listo"
        this.seconds = "";
        clearInterval(this.interval);
        this.interval = undefined;
        if (this.step == 'laugh'){
          this.startProcess('serious');
        }else{
          this.finish();
        }
      }
    },1000)
  }

  finish(){
   // this.showButton = false
    this.continueButton = true;
    this.hints = "Presiona continuar";
    this.showButton = true;
    this.btnLabel = "Comenzar de nuevo";
  }

  captureImage() {
    this.trigger.next();
  }

  proceed() {
    console.log(this.previewImage);
  }
}




