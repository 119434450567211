import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ClientService } from 'src/app/services/user.service';

@Component({
  selector: 'app-validate-with-camera',
  templateUrl: './validate-dni.component.html',
  styleUrls: ['./validate-dni.component.scss']
})
export class ValidateDniComponent implements OnInit {

  constructor(private userService : ClientService, private ngxService: NgxUiLoaderService)  {

   }
  seconds : string = ""
  step : string = "front"
  images : any = {
    front: null,
    back : null,
    document: "35273106"
  }
  startBtnShow : Boolean = true
  captureBtnShow : Boolean = false;
  continueBtnShow : Boolean = false;
  startBtnText  : string = 'Comenzar Validación';
  stepDesc : string = "Ahora vamos a necesitar tu DNI";
  btnLabel: string = '';
  width = 500
  public showWebcam = false;
  public multipleWebcamsAvailable = false;
  stream: any = null;
  front : string = ""
  back : string = ""
  status: any = null;
  trigger: Subject<void> = new Subject();
  previewImage: string = '';
  facingMode: string = 'environment';  //Set front camera
  allowCameraSwitch = false;
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  hint : string = `Tomaremos imagen del frente y dorso del DNI. 
  Es importante que haya buena luz y que no haya reflejos.
  Cuando estes listo haz click en Comenzar y sigue los pasos.`
  @ViewChild('container')
  container: ElementRef | undefined;
  
  
  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    if (this.container) {
      console.log('Width:' + this.width);
      this.width = this.container.nativeElement.offsetWidth;
    }else{
      this.width =window.innerWidth;
      console.log(this.width);
    }
  } 



  public handleImage(webcamImage: WebcamImage): void {

   // this.previewImage = webcamImage.imageAsDataUrl;
    this.images[this.step] = webcamImage.imageAsDataUrl;
    
    console.log(this.images);
    if (this.step == 'front'){
      this.startProcess('back')
    }else{
      this.finish();
    }
  }

  checkPermissions() {
    this.startBtnShow = false;
    navigator.mediaDevices.getUserMedia({
      video: true
    }).then((res) => {
      this.stream = res;
      this.status = 'My camera is accessing';
    
      this.startProcess('front')
    
    }).catch(err => {
      console.log(err);
      alert(err);
      if(err?.message === 'Permission denied') {
        this.status = 'Permission denied please try again by approving the access';
      } else {
        this.status = 'You may not having camera system, Please try again ...';
      }
    })
  }

  startProcess(step :string){
    this.step = step;
    this.captureBtnShow = true;
    if (this.step == "front"){
      this.stepDesc = "PASO 1: Frente del DNI"
      this.hint =  "Posiciona el FRENTE de tu DNI dentro del marco y presiona el botón Capturar Frente"
      this.btnLabel = "Capturar Frente"
    }else{
      this.stepDesc = "PASO 2: Dorso del DNI"
      this.hint =  "Posiciona el DORSO de tu DNI dentro del marco y presiona el botón Capturar Dorso"
      this.btnLabel = "Capturar Dorso"
    }
   
  }

  finish(){

    this.stepDesc = "DNI fotografiados con exito"
    this.hint =  "Haz click en siguiente para validar las imagenes."

    this.captureBtnShow = false;
    this.continueBtnShow = true;
    this.startBtnShow = true;
    this.startBtnText = "Comenzar de nuevo";
  }

  submit(){
    this.ngxService.start();
    this.userService.document(this.images).subscribe(
      (result) => {
        this.ngxService.stop();
       this.hint = JSON.stringify(result);
       this.front = result.img_front;
       this.back = result.img_back;
      },
      (errors) => {

        this.ngxService.stop();
       console.log(errors);
      }
    );
  }


  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
     
    }
  }
  public cameraWasSwitched(deviceId: string): void {
   
   
    this.readAvailableVideoInputs();
  }


   readAvailableVideoInputs() {
    this.startBtnShow = false;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.showWebcam = true;
        this.startProcess('front')
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public get videoOptions(): MediaTrackConstraints {
    var result: MediaTrackConstraints = {
      width: { min: 400, ideal: 900, max: 1000 },
      height: { min: 200, max: 800 }
    };
      result.facingMode =  { ideal: "environment" }
    
    return result;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }


  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
  

  imageError: string | null = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";

  fileChangeEventFront(fileInput: any) {
    this.imageError = null;

    console.log(fileInput.target.files);
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const reader = new FileReader();
        reader.readAsDataURL(fileInput.target.files[0]);
        reader.onload = () => {
          this.resizeBase64Img(String(reader.result)).then((d) => {
            console.log('resized',d);
            this.images.front = String(d);
            }
          );
          this.hint = this.images.front;
          console.log(this.images);
    };
      
    }
}

fileChangeEventBack(fileInput: any) {
  this.imageError = null;
  console.log(fileInput.target.files);


  

  if (fileInput.target.files && fileInput.target.files[0]) {
    
      const reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = () => {
          this.images.back = reader.result;
          this.resizeBase64Img(String(reader.result)).then((d) => {
            console.log('resized',d);
            this.images.back = String(d);
            }
          );
          this.hint = this.images.back;
    
  };
    
  }
}



resizeBase64Img(base64 : string) {
  console.log('resising');
  console.log(base64);
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = () => {
      var canvas = document.createElement('canvas');
      var MAX_WIDTH = 1200;
      var MAX_HEIGHT = 1200;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext('2d');
      if(ctx){
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg'));
      }else{
        reject('error canvas')
      }
    };
    img.src = base64;
  });
}



}
