
<div class="row" style="width: 100%;
margin: 0px;
border: #{{color}};
border-width: 2px;
border-style: solid;
border-top-right-radius: 10px;
border-top-left-radius: 10px;">
    <img [src]="image" height="auto" style="width: 180px;margin-top: 2px; margin-bottom: 2px; margin-left:auto; margin-right:auto; margin-top: 2px;display:block;">
</div>



<section class="section screen" style="background-color:#{{color}} !important;">
    <div class="container">
        <div class="page-title text-center">
            <h1 class="screen2">Suscripción Digital {{marcaname}}: Suscripción y Anexos </h1>
        </div><!-- end title -->
    </div><!-- end container -->
</section><!-- end section -->


     
<div class="limiter">
    <div class="container-table100">
        <div class="wrap-table100">
            <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                    <table>
                        <thead>
                            <tr class="row100 head">
                                <th class="cell100 column1" style="background-color: #{{color}}!important;">Archivos Adjuntos</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div class="table100-body js-pscroll">
                    <table>
                        <tbody>
                            <tr *ngFor="let file of files" class="row100 body"><td> <a style="color:#{{color}}" target="_blank" href="https://cont.certisend.com/web/container/app/ted/portal/psa/suscdigital/portaldoc.php?codigo={{file.codigo}}&marca={{marca}}"> <span style="margin-left:35px; font-size:19px;"> {{file.nombrearchivo}}</span> </a></td></tr>                                </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> 
                    

<div class="stickyfooter">
    <div id="sitefooter" class="container">
        <div id="copyright" class="row">
            <div class="col-sm-12">
                <p style="margin-left: auto;font-size: 1rem; margin-right:auto; display:block;">Anexos Suscripción Digital {{marcaname}} - Powered by <a href="https://www.enviocertificado.com/">EnvioCertificado.com</a></p>
            </div>
            <div class="col-md-6 col-sm-12">
                <ul class="list-inline text-right">
                </ul>
             </div>
        </div>
    </div>
</div>




<table class="wrapper" style="font-size: 12px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; table-layout: fixed;" width="100%" cellspacing="0" cellpadding="0">
    <tbody>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" bgcolor="#FFFFFF"><br />
    <p class="view-browser text-align-center" style="font-size: 12px; margin: 0 0 12px; padding: 0; font-family: Arial, sans-serif; line-height: 22px; color: #999999; text-align: center;" align="center">&nbsp;</p>
    <table class="main-content-wrap" style="border-style: solid; border-color: #E4E2E4; border-width: 10px 0px 0px; font-size: 12px; border-collapse: collapse; background-color: #ffffff; font-family: Arial,sans-serif; line-height: 22px; color: #555555;" border="0" width="600" frame="border" rules="none" cellspacing="0" cellpadding="0" align="center" bgcolor="#FFFFFF">
    <tbody>
    <tr>
    <td class="header" style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" valign="top"><br /><br />
    <table style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" width="540" cellspacing="0" cellpadding="0" align="center">
    <tbody>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" valign="top" width="260"><img class="block" src="https://certisend-asset.s3.eu-west-1.amazonaws.com/PSA/Citroen_012023.jpg" width="137" height="137" border="0" /></td>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" width="20">&nbsp;</td>
    <!-- spacer -->
    <td class="ourwebsite" style="font-size: 12px; font-family: Arial,sans-serif; line-height: 16px; color: #555555;" align="right" valign="top" width="260">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <img class="block" style="display: block;" src="https://files.enviocertificado.com/app/apl/clientes/plancirculo/templates/defectopeugeot/imagenes/divider-600x61.gif" alt="" width="600" height="61" border="0" /></td>
    </tr>
    <tr>
    <td id="iframe_module_content" class="content" style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" valign="top"><!-- Module #1 | 1 col, 540px -->
    <table style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" width="540" cellspacing="0" cellpadding="0" align="center">
    <tbody>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;" valign="top" width="352">
    <p style="font-size: 14px; font-family: Arial, sans-serif; line-height: 22px; color: #555555; margin: 0px; padding: 0px; text-align: right;">Buenos Aires, ##FECHAESCRITA##</p>
    <p style="font-size: 12px; font-family: Arial, sans-serif; line-height: 22px; color: #555555; margin: 0; padding: 0; align: left;">&nbsp;</p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px;"><strong>Hola $$CAMPO1$$, $$CAMPO2$$!:</strong></p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px;"><strong>Desde el siguiente link podr&aacute;s descargar y visualizar la Suscripci&oacute;n, los Anexos y el tal&oacute;n de la Cuota N&deg; 01, correspondientes a tu Solicitud Online:<br /><br /></strong></p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px; color: #ff1430; text-align: center;"><a href="https://cont.certisend.com/web/container/app/ted/portal/fca/suscdigital/index.php?strIdEnvio=$$IDENVIO$$&amp;strIdEnvioCod=$$IDENVIOCODIGO$$&amp;strToken=a953e01f0556ea810b5a85868e1acd6c&amp;strIdSector=95&amp;marca=F" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff1430;"><strong>Descargar Suscripci&oacute;n, Anexo y Tal&oacute;n Cuota 1</strong></span></a><br /><br /></p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px;"><strong>Es muy importante que para finalizar tu suscripci&oacute;n online confirmes la recepci&oacute;n de este mail y el consentimiento a los datos ingresados:</strong></p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px;">&nbsp;</p>
    <p style="margin-left: auto; margin-right: auto; display: block; background-color: #629c7e; width: 40%; height: auto; border-radius: 20px; background: #FF1430; text-align: center;" align="center"><strong><a style="text-decoration: none; align: auto; color: #000000; font-size: 14px; font-weight: semi-bold; font-family: Arial,sans-serif;" href="http://app.certisend.com/portal/default/clickbotonv2.asp?strIdSector=95&amp;strIdPortal=15&amp;strToken=a953e01f0556ea810b5a85868e1acd6c&amp;strIdEnvio=$$IDENVIO$$&amp;strIdEnvioCod=$$IDENVIOCODIGO$$&amp;strBoton3=Confirmar&amp;strBoton3Valor=1" target="_blank" rel="noopener">CONFIRMAR SUSCRIPCI&Oacute;N</a></strong></p>
    <p style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px;">&nbsp;</p>
    <table style="height: 66px; width: 100%; border-collapse: collapse; border-style: solid; border-color: #000000;" border="1">
    <tbody>
    <tr style="height: 66px;">
    <td style="font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; color: #000000; line-height: 22px; height: 66px; width: 100%; border-color: #000000;"><strong>Una vez que confirmes tu suscripci&oacute;n, tendr&aacute;s que realizar el pago de la Cuota N&deg; 1, por $ $$CAMPO3$$ y con vto $$CAMPO6$$, regresando al sitio web o cliqueando el siguiente bot&oacute;n:</strong></td>
    </tr>
    </tbody>
    </table>
    <br />
    <p style="margin-left: auto; margin-right: auto; display: block; background-color: #629c7e; width: 40%; height: auto; border-radius: 20px; background: #FF1430; color: #000000; text-align: center;" align="center"><strong><a style="text-decoration: none; color: #000000;" href="https://web.certisend.com/panel/app_form_web_enviosmail_templates/$$CAMPO5$$" target="_blank" rel="noopener">PAGAR CUOTA 1</a></strong></p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 14px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;"><strong><br />Te recordamos que el concesionario asignado, de acuerdo a tu domicilio y elecci&oacute;n es $$CAMPO4$$, al cual podr&aacute;s contactar ante cualquier consulta.</strong></p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 14px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;">&nbsp;</p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 14px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;">&nbsp;</p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 12px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;"><strong>PARA EL CUIDADO DE TUS DATOS PERSONALES Y LA PREVENCI&Oacute;N DE FRAUDES TE SUGERIMOS TENER PRESENTE LOS SIGUIENTES CONSEJOS DE SEGURIDAD:</strong></p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 12px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;">&nbsp;</p>
    <p style="margin: 20pt; padding: 0pt; font-weight: 300; font-size: 12px; font-family: Arial, sans-serif; line-height: 22px; color: #555555;"><strong>- Fiat Plan nunca solicitar&aacute; pagos por medios que no sean los habilitados en el siguiente link:&nbsp;</strong><strong><a href="https://www.fiatplan.com.ar/pdf/informacion-general/entidades_donde_efectuar_pagos.pdf">https://www.fiatplan.com.ar/pdf/informacion-general/entidades_donde_efectuar_pagos.pdf</a></strong><br /><strong>- En caso de ser contactado por un "agente" sospechoso, por favor: Comun&iacute;cate al 0810-222-3428 de 9:00 a 18:00hs., o inf&oacute;rmalo a tu concesionario asignado.</strong><br /><strong>- Te recomendamos SIEMPRE corroborar la identidad y autenticidad de quien te contacta.</strong></p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 12px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; text-align: justify;">&nbsp;</p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px; color: #555555; text-align: center;"><strong>FCA S.A. DE AHORRO PARA FINES DETERMINADOS</strong></p>
    <p style="margin: 0pt; padding: 0pt; font-weight: 300; font-size: 14px; font-family: Arial, sans-serif; line-height: 22px; color: #555555; text-align: center;"><strong>Atte.-</strong></p>
    </td>
    </tr>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;">
    <p style="font-size: 14px; font-family: Arial, sans-serif; line-height: 22px; color: #555555; margin: 0px; padding: 0px; text-align: right;">&nbsp;</p>
    </td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    <tr>
    <td class="footer" style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;" valign="top">
    <table style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;" width="600" cellspacing="0" cellpadding="0" align="center" bgcolor="#F0F0F0">
    <tbody>
    <tr>
    <td class="footer-content" style="font-size: 12px; border-top-color: #D4D6D4; font-family: Arial, sans-serif; line-height: 22px; color: #555555; border-top-width: 1px; border-top-style: solid;" valign="top">
    <table style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;" width="540" cellspacing="0" cellpadding="0" align="center">
    <tbody>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555; width: 240px;" valign="top" width="230">&nbsp;</td>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555; width: 285px;" valign="top" width="250">
    <h4 style="margin: 10px 0pt 14px; padding: 0pt; font-weight: bold; font-size: 12px; line-height: 18px; color: #252525;"><span style="color: #252525;">ERN</span></h4>
    <a style="text-decoration: underline; color: #3279bb;" href="https://instagram.com/apipagos?igshid=63n74g6odc38" target="_blank" rel="noopener"><img src="https://files.enviocertificado.com/app/apl/clientes/sysworld/images/instagram2.png" alt="" width="28" height="28" border="0" /></a><a style="text-decoration: underline; color: #3279bb;" href="https://www.linkedin.com/company/sysworld/about" target="_blank" rel="noopener"><img src="https://files.enviocertificado.com/app/apl/clientes/sysworld/images/linkedin3.png" alt="" width="28" height="28" border="0" /></a></td>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555; width: 14px;" width="10">&nbsp;</td>
    <!-- spacer --></tr>
    </tbody>
    </table>
    <br />
    <table style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;" width="540" cellspacing="0" cellpadding="0" align="center">
    <tbody>
    <tr>
    <td style="font-size: 12px; line-height: 22px; font-family: Arial, sans-serif; color: #555555;" align="center" valign="top">El siguiente env&iacute;o se realiza a trav&eacute;s de la plataforma ERN (powered by Enviocertificado.com) por solicitud del remitente. Dicho env&iacute;o est&aacute; sujeto a ##TIPOCERTIFICACION## (ID: app1 Certisend - ##IDENVIOLEGAL## - ##FECHA##) y almacenado por la empresa Sysworld Servicios S.A. quien no ser&aacute; parte interesada en la comunicaci&oacute;n que es confidencial y estar&aacute; garantizada bajo estrictas normas de seguridad inform&aacute;tica.<br />Ante cualquier duda consulte nuestros <a style="text-decoration: underline; color: #3279bb;" href="https://sysworld.com.ar/web/legales.php" target="_blank" rel="noopener">T&eacute;rminos y Condiciones</a><br /><br /><br /></td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>