<header><h1>Bases y condiciones</h1></header>

<div class="container text-center mb-5">
  <div class="col-md-6 mx-auto">
    <div class="image mt-4 mb-3"><img src="" alt="" /></div>
    <h2><span>Validación correcta</span></h2>

    <div class="checks d-flex flex-column mt-4">
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> CHEVROLET ONIX JOY
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" />El pago del derecho
            de ingreso no dará derecho a participar del sorteo
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> Importe 1er débito
            $9.841,13
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> Acepto el ajuste
            del valor nominal previsto en el art. 6 inc. a de estas bases
            técnicas (solo si el bien elegido es valor nominal)
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> Autocredito S.A. de
            capitalización, no cuenta con cobradores a domicilio
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> Duración máxima del
            título es de 300 cuotas pagas
          </div>
        </label>
      </div>
      <div class="radioBox">
        <label>
          <div>
            <input type="radio" name="color" value="azul" /> El pago del derecho
            de ingreso no dará derecho a participar del sorteos
          </div>
        </label>
      </div>

      <div class="col-md-12 control">
        <input class="me-3" type="checkbox" style="width: initial" />
        <label for="">ACEPTO TERMINOS Y CONDICIONES</label>
      </div>
    </div>
    <div>
      <button class="basic mt-4" routerLink="/validar/validar-codigo">
        Suscribite
      </button>
    </div>
    <div>
      <button class="transparent mt-4" routerLink="/cancelar-operacion">
        CANCELAR OPERACIÓN
        <div>comentario</div>
      </button>
    </div>
    <div>
      <div class="logo mt-3">
        <img src="assets/images/logo_grey.png" alt="" />
      </div>
    </div>
  </div>
</div>
