<header><h1>Escanea tu DNI</h1></header>

<div #container class="container text-center">
  <div class="row">
    <div class="col-md-10 mx-auto"  style="padding: 0px;">
      <div style="position: relative;" *ngIf="showWebcam;else other_content">
        <webcam [height]="500" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
          [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
          [videoOptions]="videoOptions"
          [imageQuality]="1"
          [imageType]="'image/jpeg'"
          (cameraSwitched)="cameraWasSwitched($event)"
          (initError)="handleInitError($event)"> 
        </webcam>

        <div style="position: absolute; top: 40%;text-align: center; width: 100%; font-size: medium;">
          
          <br/>
          <h1><span style="color: white; font-weight: 500;">{{seconds}}</span></h1>
        </div>
        <!--
        <div class="image mt-md-5 mt-4 mb-4">
          <button (click)="captureImage()" >{{btnLabel}}</button>
        </div>
       -->
      </div>
        <ng-template #other_content>
          <div class="image mt-md-5 mt-4 mb-4">
          <img src="assets/images/dni.png" alt="" />
        </div></ng-template>

      
      <h2><span>{{stepDesc}}</span></h2>

      <div class="col-md-6 mx-auto">

        <p>
        {{hint}}
        </p>
      </div>
      <button class="basic mt-4" (click)="readAvailableVideoInputs()" *ngIf="startBtnShow">
        {{startBtnText}}
      </button>
      
      <button class="basic mt-4" *ngIf="captureBtnShow" (click)=" triggerSnapshot()">
        {{btnLabel}}
      </button>
      <br/>
      <button class="basic mt-4" *ngIf="continueBtnShow" (click)="submit()"> <!-- //routerLink="/validar/agregar-documento-fallo"> -->
        Siguiente
      </button>
      <input (change)="fileChangeEventFront($event)" type="file" accept="image/jpeg" capture="environment">
      <input (change)="fileChangeEventBack($event)" type="file" accept="image/jpeg" capture="environment">
      <div class="image mt-md-5 mt-4 mb-4">
        <br/>
        <img  [src]="front" *ngIf="front != '' && continueBtnShow "/> <!-- //routerLink="/validar/agregar-documento-fallo"> -->
          
        <br/>
        <img [src]="back" *ngIf="back != ''  && continueBtnShow "/>
      </div>
      <div>  
        <button class="transparent mt-4" routerLink="/cancelar-operacion">
          CANCELAR OPERACIÓN
          <div>comentario</div>
        </button>
      </div>
      
      <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
    </div>
  </div>
</div>
