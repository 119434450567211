

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { ApiBridgeService } from '../api-bridge.service';
import { finalize, catchError } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private requestInProgressSource = new Subject<any>();
  requestInProgress$ = this.requestInProgressSource.asObservable();
  constructor(
    private apiBridge: ApiBridgeService,
    private router: Router,
    private httpClient: HttpClient
  ) { } 
  document(data: any): Observable<any> {
    return this.apiBridge.post(`validations/documents/dni/full`, data)
  }

   subscription(data: any): Observable<any> {
    return this.apiBridge.get(`customizations/fca/subscription`, data)
  }
  
  attachments(data: any): Observable<any> {
    return this.apiBridge.get(`customizations/fca/attachments`, data)
  }
 


}
