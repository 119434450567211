<header><h1>Validación de identidad</h1></header>

<div class="container text-center">
  <div class="image mt-md-5 mb-4"><img src="" alt="" /></div>
  <h2><span>No pudimos validar la identidad con las preguntas</span></h2>

  <p>Hacerlo respondiendo algunas breves preguntas</p>
 
  <div class="col-md-6 mx-auto bg">
    <div class="title p-3">Contactanos</div>
    <div class="p-3">

        <h3><span>PUNTA ALTA, Buenos Aires</span></h3>
        <p>Cesar Treffinger</p>
        <p>Rivadavia 166, Punta Alta, Buenos Aires</p>
        <p>02932-426890</p>
        <p>Facebook</p>
    </div>
  </div>
</div>
