import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelFormComponent } from './components/cancel-form/cancel-form.component';
import { HomeComponent } from './components/home/home.component';
import { ValidateDniFailedComponent } from './components/scan-document/validate-dni-failed/validate-dni-failed.component';
import { ValidateProofLifeComponent } from './components/scan-document/validate-proof-life/validate-proof-life.component';
import { ValidateSuccessComponent } from './components/scan-document/validate-success/validate-success.component';
import { ValidateDniComponent } from './components/scan-document/validate-dni/validate-dni.component';
import { ValidateWithOptionsFailedComponent } from './components/scan-document/validate-with-options-failed/validate-with-options-failed.component';
import { ValidateWithOptionsComponent } from './components/scan-document/validate-with-options/validate-with-options.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ValidateCodeFailedComponent } from './components/validate-code/validate-code-failed/failed.component';
import { ValidateCodeSucessComponent } from './components/validate-code/validate-code-success/sucess.component';
import { ValidateCodeComponent } from './components/validate-code/validate-code/validate-code.component';
import { ValidateDataComponent } from './components/validate/data/data.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { SuscripcionComponent } from './components/suscripcion/suscripcion.component';
import { AttachedFilesComponent } from './components/attached-files/attached-files.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'bienvenido',
        pathMatch: 'full',
      },
      { path: '', component: HomeComponent },
      {path: 'suscripcion', component: SuscripcionComponent },

      {path: 'attachedFiles', component: AttachedFilesComponent },

      { path: 'validar/datos', component: ValidateDataComponent },
      { path: 'validar/agregar-documento', component: ValidateDniComponent },
      { path: 'validar/agregar-documento-fallo', component: ValidateDniFailedComponent },
      { path: 'validar/prueba-de-vida', component: ValidateProofLifeComponent },
      { path: 'validar/validar-con-preguntas', component: ValidateWithOptionsComponent },
      { path: 'validar/validar-con-preguntas-fallo', component: ValidateWithOptionsFailedComponent },
      { path: 'validar/validar-correcto', component: ValidateCodeComponent },
      { path: 'metodo-de-pago', component: PaymentMethodsComponent },
      { path: 'bases-y-condiciones', component: TermsAndConditionsComponent },
      { path: 'validar/validar-codigo', component: ValidateCodeComponent },
      { path: 'validar/validar-codigo-exitoso', component: ValidateCodeSucessComponent },
      { path: 'validar/validar-codigo-fallido', component: ValidateCodeFailedComponent },
      { path: 'validacion-exitosa', component: ValidateSuccessComponent },
      { path: 'cancelar-operacion', component: CancelFormComponent },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
