

<header><h1>Escanea tu DNI</h1></header>

<div #container class="container text-center">
  <div class="row">
    <div class="col-md-10 mx-auto" style="padding: 0px;">
        <div style="position: relative;" *ngIf="stream;else other_content">
          <webcam [height]="500" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"> 
          </webcam>

          <div style="position: absolute; top: 40%;text-align: center; width: 100%; font-size: medium;">
            
            <br/>
            <h1><span style="color: white; font-weight: 500;">{{seconds}}</span></h1>
          </div>
          <!--
          <div class="image mt-md-5 mt-4 mb-4">
            <button (click)="captureImage()" >{{btnLabel}}</button>
          </div>
         -->
        </div>
          <ng-template #other_content>
            <div class="image mt-md-5 mt-4 mb-4">
            <img src="assets/images/prueba_de_vida.png" alt="" />
          </div></ng-template>
      
     
      <h2><span>{{hints}}</span></h2>

      <div class="col-md-6 mx-auto">

        <p>Apunta el telefono hacia el centro de
            tu cara y sigue los pasos que aparecerán en la pantalla</p>
      </div>

      <!-- <p *ngIf="status">Status here: {{status}}</p> -->
      <div *ngIf="previewImage">
          <img [src]="previewImage" height="250" width="250">
         <!--<button (click)="proceed()">Proceed</button>--> 
      </div>
      <div>  
      <button class="basic mt-4" *ngIf="showButton" (click)="startProcess('laugh')">{{btnLabel}}</button>
      </div>
      <div>  
      <button *ngIf="continueButton" class="basic mt-4" routerLink="/validacion-exitosa"> Continuar </button>
      </div>
      <div>  
        <button class="transparent mt-4" routerLink="/cancelar-operacion">
          CANCELAR OPERACIÓN
          <div>comentario</div>
        </button>
      </div>
      
      <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
    </div>
  </div>
</div>
