<header><h1>Escanea tu DNI</h1></header>

<div class="container text-center">
<div class="image mt-md-5 mb-4"><img src="" alt=""></div>
  <h2><span>Intruducir código
    de verificación</span></h2>

    <p>Acabamos de enviarte un SMS
        con un código de verificación.
        Ingresa el código, abajo</p>

        <div class="code mb-4">
            <input class="border" type="text" />
            <input class="border" type="text" />
            <input class="border" type="text" />
            <input class="border" type="text" />
        </div>

    <button class="basic mt-4" routerLink="/validar/validar-codigo-exitoso">Enviar</button>
    <p class="mt-3">No recibí el código.</p>


    
    <div class="mt-5">
        <button class="transparent mt-4" routerLink="/cancelar-operacion">
          CANCELAR OPERACIÓN
          <div>comentario</div>
        </button>
      </div>
      <div>
        <div class="logo mt-3">
          <img src="assets/images/logo_grey.png" alt="" />
        </div>
      </div>
</div>
