<header><h1>Validación de DNI</h1></header>

<div class="container text-center">
  <div class="col-md-6 mx-auto">
    <div class="image mt-4 mb-3"><img src="assets/images/check.png" alt="" /></div>
    <h2><span>Validación correcta</span></h2>

    <div class="checks d-flex flex-column mt-4">
      <div class="radioBox text-start">
        <label>  <div><input type="radio" name="color" value="azul" /> 
         <span>Persona expuesta Políticamente</span> 
           <p>Quien desempeña o desempeñó
            funciones públicas o es familiar de 
            un funcionarios- Conocé más</p>  </div> </label>
      </div>
      <div class="radioBox text-start">
        <label> <div><input type="radio" name="color" value="negro" />
            <span>Sujeto Obligado</span>
            <p> Quien desempeña o desempeñó
            funciones públicas o es familiar de 
            un funcionarios- Conocé más</p></div>   </label>
      </div>
      <div class="radioBox text-start">
        <label>
          <div><input type="radio" name="color" value="amarillo" />
          <span> Ninguna de las anteriores</span>
        </div> 
          
        </label>
      </div>
    </div>

    <button class="basic mt-4" routerLink="/metodo-de-pago">
      Siguiente
    </button>
    <div>   
      <button class="transparent mt-4" routerLink="/cancelar-operacion">
        CANCELAR OPERACIÓN
        <div>comentario</div>
      </button>
    </div>
    
    <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
  </div>
</div>
