import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validate-failed',
  templateUrl: './validate-dni-failed.component.html',
  styleUrls: ['./validate-dni-failed.component.scss']
})
export class ValidateDniFailedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
