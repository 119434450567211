<!-- Full height wrapper -->

<header id="header" style="background-color: transparent; height: 60px;" class="container">

    <h1>
        <img src="">
    </h1>

    



</header>

<div style="background-color: #ecf0f1;" id="wrapper">

    <!-- Main page header -->

    <!-- /Main page header -->

    <!-- Main page container -->
    <section class="container" role="main">

        <!-- Grid row -->
        <div class="row">

            <!-- Data block -->
            <article class="col-sm-12">
                <div class="data-block">
                    <header style="height:50px">
                        <h2 style="float: left;">{{asunto}}</h2>
                        <ul class="data-header-actions">

                            <li><a href="javascript:window.print();">Imprimir</a></li>
                        </ul>
                    </header>
                    <section class="tab-content">

                        <div *ngIf="class" class="alert fade in" [ngClass]="class">
                            <button class="close" data-dismiss="alert">×</button>
                            <strong>{{message}}</strong> 
                        </div>


                        <div class="row">
                            <div class="col-sm-4">
                                <div class="well">
                                    <h4>Remitente:</h4>
                                    <dl>
                                        <dt>Email:</dt>
                                        <dd>{{remitente}}</dd>

                                    </dl>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="well">
                                    <h4>Destinatario</h4>
                                    <dl>
                                        <dt>Email:</dt>
                                        <dd>{{destinatario}}</dd>

                                    </dl>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="well">
                                    <h4>Datos del envío</h4>
                                    <dl>
                                        <dt>IdEnvio:</dt>
                                        <dd>{{id}}</dd>
                                        <dt>Fecha de Envío:</dt>
                                        <dd>{{fechaEnvio}}</dd>
                                        <dt>Estado:</dt>
                                        <dd>{{estado}}</dd>

                                        <dt>Vencimiento suscripción:</dt>
                                        <dd>{{fecha}}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div style="padding: 10px" class="row">

                            <article class="col-sm-12">
                                <div class="gray data-block">

                                    <header style="height:50px">
                                        <h2 style="float: left;">Mensaje</h2>
                                    </header>

                                    <section class="wizard">
                                        <table class="wrapper"
                                            style="font-size: 12px; font-family: Arial,sans-serif; line-height: 22px; color: #555555; table-layout: fixed;"
                                            width="100%" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td style="font-size: 12px; line-height: 22px; font-family: Arial,sans-serif; color: #555555;"><br>
                                        
                                                            <div  [innerHtml]="html"></div>
                                         =

                                                            
                                         
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>

                                </div>
                            </article>

                        </div>
                        <!-- /Grid row -->




                    </section>
                </div>
            </article>
            <!-- /Data block -->

        </div>
        <!-- /Grid row -->

    </section>
    <!-- /Main page container -->

</div>
<!-- /Full height wrapper -->

<!-- Main page footer -->
<footer id="footer">
    <div class="container">

        <!-- Footer info -->
        <p><a href="http://sysworld.com.ar/legal.asp" target="_blank">Copyright © 2010 - 2022</a> | Sysworld Servicios
            S.A.</p>

        <!-- Footer nav -->
        <ul>

        </ul>
        <!-- /Footer nav -->

        <!-- Footer back to top -->
        <a href="#top" class="btn btn-back-to-top" title="" data-original-title="Subir"><span
                class="elusive icon-arrow-up"></span></a>

    </div>

</footer>

