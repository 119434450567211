import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer, SafeHtml, Title} from '@angular/platform-browser'

@Component({
  selector: 'app-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  id: string | null = ""
  code: string | null = ""
  fecha : string = "";
  estado: string = "";
  destinatario : string = "";
  remitente : string = "";
  asunto : string = "";
  fechaEnvio : string = "";
  message  = ""
  html :SafeHtml = ""
  class = ""
  constructor(private route: ActivatedRoute,private title: Title, private userService : ClientService, private sanitizer: DomSanitizer) { 
   

  }

  ngOnInit(): void {
    this.title.setTitle("Suscripción Digital");
    this.route.queryParamMap
  .subscribe((params) => {
    this.id = params.get('strIdEnvio');
    this.code = params.get('strIdEnvioCod');
    this.loadSuscription();
   
  }
)

  }

    loadSuscription(){
      this.userService.subscription({id: this.id,code: this.code}).subscribe(
        (result) => {
          console.log(result.state);
          if (result.state == "0") {
            this.class = "alert-danger";
          }else if (result.state == "2"){
            this.class = "alert-info";
          }else {
            this.class = "alert-success";
          }
          this.html = this.sanitizer.bypassSecurityTrustHtml(result.html.replace(/href="[^"]*"/gi,"")); 
          this.asunto = result.asunto;
          this.fechaEnvio = result.fechaenvio;
          this.destinatario = result.paraemail;
          this.remitente = result.remitente;
          this.estado = result.estado;
          this.fecha = result.fecha;
          this.message = result.message;
        
        },
        (errors) => {
  
        }
      );
  }

  
}
