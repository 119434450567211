import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ValidateSuccessComponent } from './components/scan-document/validate-success/validate-success.component';
import { ValidateDniFailedComponent } from './components/scan-document/validate-dni-failed/validate-dni-failed.component';
import { ValidateWithOptionsComponent } from './components/scan-document/validate-with-options/validate-with-options.component';
import { ValidateWithOptionsFailedComponent } from './components/scan-document/validate-with-options-failed/validate-with-options-failed.component';
import { ValidateDniComponent } from './components/scan-document/validate-dni/validate-dni.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { CancelFormComponent } from './components/cancel-form/cancel-form.component';
import { ValidateCodeFailedComponent } from './components/validate-code/validate-code-failed/failed.component';
import { ValidateCodeSucessComponent } from './components/validate-code/validate-code-success/sucess.component';
import { ValidateCodeComponent } from './components/validate-code/validate-code/validate-code.component';
import { ValidateDataComponent } from './components/validate/data/data.component';
import { ValidateProofLifeComponent } from './components/scan-document/validate-proof-life/validate-proof-life.component';
import { WebcamModule } from 'ngx-webcam';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SuscripcionComponent } from './components/suscripcion/suscripcion.component';
import { AttachedFilesComponent } from './components/attached-files/attached-files.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ValidateDataComponent,
    ValidateSuccessComponent,
    ValidateDniFailedComponent,
    ValidateWithOptionsComponent,
    ValidateWithOptionsFailedComponent,
    ValidateDniComponent,
    PaymentMethodsComponent,
    TermsAndConditionsComponent,
    CancelFormComponent,
    ValidateCodeComponent,
    ValidateCodeSucessComponent,
    ValidateCodeFailedComponent,
    ValidateProofLifeComponent,
    SuscripcionComponent,
    AttachedFilesComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    WebcamModule,
    NgxUiLoaderModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
