import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment as env } from '../environments/environment';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiBridgeService {
  token = localStorage.getItem('token');

  private requestInProgressSource = new Subject<any>();
  requestInProgress$ = this.requestInProgressSource.asObservable();
  constructor(private httpClient: HttpClient) { }

  post(url: string, body: any, headers?: any): Observable<any> {
    this.requestInProgressSource.next(true)
    return this.httpClient.post(`${env.api_url}/${url}`, body, {
      headers: new HttpHeaders({ ...headers, 'Content-Type': 'application/json; charset=utf-8' })
    }).pipe(catchError((err: any, _: Observable<Object>) => {
      return throwError(err);
    }), finalize(() => { this.requestInProgressSource.next(false) }));
  }

  postMultipart(url: string, body: any, headers?: any): Observable<any> {
    this.requestInProgressSource.next(true)
    return this.httpClient.post(`${env.api_url}/${url}`, body, {
      headers: new HttpHeaders({ ...headers, 'accept': 'application/json, text/plain, */*' })
      // headers: new HttpHeaders({ ...headers, 'Content-Type': 'multipart/form-data;' })
    }).pipe(catchError((err: any, _: Observable<Object>) => {
      return throwError(err);
    }), finalize(() => { this.requestInProgressSource.next(false) }));
  }


  get(url: string, queryParams: any = {}): Observable<any> {
    let params = new HttpParams();
    if (queryParams) {
      params = Object.getOwnPropertyNames(queryParams).reduce(
        (p, key) => p.set(key, queryParams[key]),
        new HttpParams()
      );
    }

    this.requestInProgressSource.next(true)
    return this.httpClient.get(`${env.api_url}/${url}`, { params }).pipe(finalize(() => { this.requestInProgressSource.next(false) }))
  }


}
