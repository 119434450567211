<header><h1>Validación de DNI</h1></header>

<div class="container text-center mb-5">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <div class="image mt-md-5 mb-4"><img src="" alt="" /></div>
      <h2>
        <span>No pudimos validar la identidad con la foto de tu DNI</span>
      </h2>

      <p>
        ¿Querés hacerlo respondiendo <br />
        algunas breves preguntas?
      </p>

      <div class="image pointer" routerLink="/validar/validar-con-preguntas">
        <img src="assets/images/si.png" alt="">
      </div>
      
      <div class="mt-5">  
        <button class="transparent mt-4" routerLink="/cancelar-operacion">
          CANCELAR OPERACIÓN
          <div>comentario</div>
        </button>
      </div>
      
      <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
    </div>
  </div>
</div>
