<header><h1>Código de verificación</h1></header>

<div class="container text-center">
  <div class="row">
    <div class="col-md-10 mx-auto">

      <h2 class="mt-md-5 mt-4"><span>Código
        correcto</span></h2>
      <div class="image my-4"><img src="assets/images/check.png" alt=""></div>
        
        <div class="image"><img src="" alt=""></div>
      </div>

      
      <div class="mt-5">
        <div class="logo mt-3">
          <img src="assets/images/logo_grey.png" alt="" />
        </div>
      </div>
    </div>
  </div>
