<header><h1>Validación de DNI</h1></header>

<div class="container text-center">
<div class="image mt-md-5 mb-4"><img src="" alt=""></div>
  <h2 class="mb-4"><span>No pudimos validar la identidad con la foto</span></h2>

    <p>¿Querés hacerlo respondiendo <br> algunas breves pregunta</p>
        
    <div class="row mt-4">
      <div class="col-md-8 mx-auto">

        <div class="row">
          <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
      
      <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
      
      <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
      
      <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
      
      <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
      
      <div class="col-12 mb-4"> 
        <div class="control-questions p-3">
          <textarea type="text" placeholder="TU APELLIDO MATERNO ES VAZQUEZ?"></textarea>
        </div>
      </div>
    </div>
    
  </div>
  
</div>
    <button class="basic mt-4" routerLink="/validar/prueba-de-vida">
      Siguiente
    </button>
    <div>  
      <button class="transparent mt-4" routerLink="/cancelar-operacion">
        CANCELAR OPERACIÓN
        <div>comentario</div>
      </button>
    </div>
    
    <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
</div>
