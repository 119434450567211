<header><h1>Autocreditos</h1></header>

<div class="container text-center mb-5">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <div class="avatar mx-auto my-3">
        <img src="assets/images/avatar.png" alt="" />
      </div>
      <h2>
        <span>¡Bienvenida</span><br />
        Claudia!
      </h2>

      <p>Usted esta suscribiendo</p>
      <span class="tag my-2">ONIX 2022</span>
      <p>Plan 300 - <span>CÓDIGO 300</span></p>

      <form class="my-4 mx-auto p-3" action="">
        <div class="row">
          <div class="col-12 control">
            <div>ID de la venta de la precarga</div>
            <input type="text" />
          </div>

          <div class="row">
            <div class="col-md-8 control">
              <div>Nombre y Apellido</div>
              <input type="text" />
            </div>
            <div class="col-md-2 control">
              <div>DNI</div>
              <input type="text" />
            </div>
            <div class="col-md-2 control">
              <div>Estado Civil</div>
              <input type="text" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 control">
              <div>Genero</div>
              <input type="text" />
            </div>
            <div class="col-md-4 control">
              <div>Nacionalidad</div>
              <input type="text" />
            </div>
            <div class="col-md-4 control">
              <div>Fecha Nacimiento</div>
              <input type="text" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 control">
              <div class="d-flex flex-column">
                <div class="col-12 control">
                  <div>Telefono</div>
                  <input type="phone" />
                </div>
                <div class="col-12 control">
                  <div>Mail</div>
                  <input type="email" />
                </div>
              </div>
            </div>
            <div class="col-md-6 control">
              <button class="modify mt-4">Modificar Dato</button>
            </div>
          </div>
        </div>
      </form>
      <div>
        <button class="basic mt-4" routerLink="/validar/agregar-documento">
          Siguiente
        </button>
      </div>
      <div>  
        <button class="transparent mt-4" routerLink="/cancelar-operacion">
          CANCELAR OPERACIÓN
          <div>comentario</div>
        </button>
      </div>
      
      <div class="logo mt-3"><img src="assets/images/logo_grey.png" alt="" /></div>
    </div>
  </div>
</div>
