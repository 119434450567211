import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validate-with-options',
  templateUrl: './validate-with-options.component.html',
  styleUrls: ['./validate-with-options.component.scss']
})
export class ValidateWithOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
